import React from "react"

import Layout from "../../../components/layout"

const EmojiplyPrivacyPolicy = () => (
  <Layout>
    <h1>emojiply: Privacy Policy</h1>

    <p>Leo Picado built the emojiply app as a Free app. This SERVICE is provided by
      Leo Picado at no cost and is intended for use as is.</p>

    <p>This page is used to inform visitors regarding my policies with the collection,
      use, and disclosure of Personal Information if anyone decided to use my Service.</p>

    <p>If you choose to use my Service, then you agree to the collection and use of
      information in relation to this policy. The Personal Information that I collect is
      used for providing and improving the Service. I will not use or share your information with
      anyone except as described in this Privacy Policy.</p>

    <p>If you choose to use my Service, then you agree to the collection and use of
      information in relation to this policy. The Personal Information that I collect is
      used for providing and improving the Service. I will not use or share your information with
      anyone except as described in this Privacy Policy.</p>

    <p><strong>Information Collection and Use</strong></p>
    <p>For a better experience, while using our Service, I may require you to provide us with
      certain personally identifiable information. The information that I request will be
      retained on your device and is not collected by me in any way.</p>

    <p><strong>Log Data</strong></p>
    <p>I want to inform you that whenever you use my Service, in a case of an error in the app
      I collect data and information (through third party products) on your phone called Log
      Data. This Log Data may include information such as your device Internet Protocol
      (“IP”) address, device name, operating system version, the configuration of the app
      when utilizing my Service, the time and date of your use of the Service, and other
      statistics.</p>

    <p><strong>Security</strong></p>
    <p>I value your trust in providing us your Personal Information, thus we are striving
      to use commercially acceptable means of protecting it. But remember that no method
      of transmission over the internet, or method of electronic storage is 100% secure
      and reliable, and I cannot guarantee its absolute security.</p>

    <p><strong>Children’s Privacy</strong></p>
    <p>These Services do not address anyone under the age of 13. I do not knowingly
      collect personally identifiable information from children under 13. In the case
      I discover that a child under 13 has provided me with personal information, I immediately
      delete this from our servers. If you are a parent or guardian and you are aware that
      your child has provided us with personal information, please contact me so that I
      will be able to do necessary actions.</p>

    <p><strong>Changes to This Privacy Policy</strong></p>
    <p>I may update our Privacy Policy from time to time. Thus, you are advised to
      review this page periodically for any changes. I will notify you of any changes
      by posting the new Privacy Policy on this page.</p>

    <p>This policy is effective as of 2020-11-13</p>

    <p><strong>Contact Us</strong></p>
    <p>If you have any questions or suggestions about my Privacy Policy, do not
      hesitate to contact me at emojiply@leopicado.com.</p>

    <p>This document was based on a template provided by <a href="https://app-privacy-policy-generator.nisrulz.com/"
                                                            target="_blank" rel="noopener noreferrer">App Privacy Policy
      Generator</a>.</p>
  </Layout>
)

export default EmojiplyPrivacyPolicy
